@import-normalize;

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: row;
  height: 99vh;
  margin: 0.5vh;
}

a {
  text-decoration: none;
}

ul,
ol {
  margin-block-end: 0;
  margin-block-start: 0;
}

.Header {
  margin: 0.5em;
  text-align: center;
  background-color: #b5acb4;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
}

.Header_title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0.25em;
  color: #2a1d28;
  font-size: 4.3em;
  font-family: "Permanent Marker", sans-serif;
}

.Header_title:hover {
  cursor: pointer;
}

.Header_links {
  align-items: center;
  height: fit-content;
  justify-content: space-evenly;
  display: flex;
  flex-flow: row wrap;
}

.Header_link {
  color: #473144;
  font-size: 1.9rem;
  font-weight: 400;
  width: fit-content;
  font-family: "Covered By Your Grace", sans-serif;
}

.Header_link:hover,
.Header_link:focus {
  cursor: pointer;
  font-style: italic;
  transition: all 0.2s ease-out;
  transform: scale(1.05);
  color: #2a1d28;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.SearchBar {
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 0;
}

.SearchBar_input {
  border-radius: 0.3em;
  border: none;
  appearance: none;
  outline: none;
  width: 250px;
  height: 30px;
  font-size: 1em;
}

.SearchBar_input:focus::placeholder {
  color: transparent;
}

.SearchBar_input::placeholder {
  color: #2a1d28;
  transition: color 0.3s ease;
}

.Authentication_buttons {
  font-family: "Covered By Your Grace", sans-serif;
  font-size: 1.9em;
  color: #473144;
  background-color: transparent;
  border: none;
  display: flex;
}

.Authentication_buttons:focus,
.Authentication_buttons:hover {
  cursor: pointer;
  font-style: italic;
  transition: all 0.2s ease-out;
  transform: scale(1.1);
  color: #2a1d28;
  outline: none;
}

.AboutMe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: min-content;
  color: #2a1d28;
  margin: 1em;
}

.AboutMe_title {
  font-size: 3.5em;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  font-family: "Covered By Your Grace", sans-serif;
  margin: 0.25em;
}

.AboutMe_image {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 17em;
  width: 20em;
  border-radius: 0.5em;
  object-fit: cover;
  margin: 0 0 2em 0;
}

.AboutMe_paragraph {
  font-size: 1.8em;
  display: flex;
  justify-self: center;
  color: #2a1d28;
  width: 75%;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Dongle", sans-serif;
}

.RecipeGrid_container {
  height: 100%;
  overflow-y: scroll;
}

.RecipeGrid_container::-webkit-scrollbar {
  display: none;
}

.RecipeGrid {
  margin: -0.25em 0.25em -0.25em 0.25em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.RecipeGrid_item {
  flex: 1;
  margin: 1em;
}

.RecipeCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.3em;
  background-color: #dcd3e84d;
  box-shadow: 4px 8px 8px 4px #d0cad9c4;
  min-width: 290px;
  height: 300px;
  border: 1px #d0cad9c4;
  font-family: "Dongle", sans-serif;
  transition: ease-in-out 0.2s;
}

.RecipeCard:hover {
  box-shadow: 10px 10px 10px 10px #d0cad9c4;
  transform: scale(1.01);
}

.RecipeCard_title {
  font-size: 1.8em;
  text-align: center;
  color: #473144;
  font-family: "Covered By Your Grace", sans-serif;
  letter-spacing: 0.07em;
  margin-block-start: 0;
  margin-block-end: 0;
}

.RecipeCard_image_container {
  display: flex;
  justify-content: center;
}

.RecipeCard_image {
  justify-content: center;
  align-content: center;
  border-radius: 0.5em;
  object-fit: cover;
  height: 150px;
  width: 250px;
  margin: 0.5em;
}

.RecipeCard_times {
  text-align: center;
  justify-content: center;
  color: #473144;
  font-size: 1.6em;
}

.RecipeDetails {
  display: flex;
  flex-direction: column;
  width: 97%;
  margin: 0.5em;
  background-color: #dcd3e84d;
  font-family: "Dongle", sans-serif;
  box-shadow: 2px 8px 8px 4px #d0cad9c4;
  border-radius: 0.5em;
  color: #2a1d28;
  overflow-y: scroll;
  overflow-x: hidden;
}

.RecipeDetails::-webkit-scrollbar {
  display: none;
}

.RecipeDetails_header {
  justify-content: center;
  align-content: center;
  text-align: center;
}

.RecipeDetails_header_title {
  width: 100%;
  margin: 0.25em 0 0 0;
  padding: 0;
  text-align: center;
  color: #2a1d28;
  font-size: 4em;
  font-family: "Covered By Your Grace", sans-serif;
}

.RecipeDetails_header_description {
  display: flex;
  justify-content: center;
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
  align-content: center;
  margin: 0.25em 3em;
  font-size: 1.5em;
  letter-spacing: 0.07em;
  color: #2a1d28;
}

.RecipeDetails_header_image {
  height: 17em;
  margin: 1em;
  min-width: 60%;
  border-radius: 0.5em;
  object-fit: cover;
}

.RecipeDetails_body {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.RecipeDetails_body_short_details {
  width: 50%;
  text-align: center;
}

.RecipeDetails_body_header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 2.2em;
  color: #2a1d28;
}

.RecipeDetails_body_time {
  font-size: 1.7em;
}

.RecipeDetails_body_ingredients {
  width: 50%;
  text-align: center;
  flex-wrap: wrap;
}

.ReactMarkdown {
  font-family: "Dongle", sans-serif;
  display: flex;
  justify-content: center;
  font-size: 1.7em;
  color: #2a1d28;
  margin: 0;
  text-align: left;
  width: 100%;
  padding: 0;
  margin: 0;
}

.RecipeDetails_body_method {
  display: flex;
  text-align: center;
  flex-flow: column wrap;
  justify-content: center;
  width: 80%;
}

.CommentSection {
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 80%;
}

.CommentForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Covered By Your Grace", sans-serif;
  color: #2a1d28;
  border-radius: 0.4em;
}

.CommentForm_header {
  display: flex;
  justify-content: flex-start;
  font-size: 2em;
  width: 80%;
}

.CommentForm_input {
  display: flex;
  justify-content: center;
  border-radius: 0.3em;
  border: none;
  appearance: none;
  outline: none;
  width: 80%;
  height: 4em;
  font-size: 1.7em;
  line-height: 80%;
  resize: none;
  padding: 0.5em;
  margin: 0;
  font-family: "Dongle", sans-serif;
}

.CommentForm_submit_button {
  padding: 0.5em;
  color: #473144;
  background-color: transparent;
  font-size: 1.9rem;
  font-weight: 400;
  font-family: "Covered By Your Grace", sans-serif;
  display: flex;
  justify-content: flex-end;
  width: 80%;
  border: none;
  outline: none;
  border-radius: 0.25em;
  transition: transform 0.2s;
}

.CommentForm_submit_button:hover {
  cursor: pointer;
  color: #2a1d28;
  font-style: italic;
}

.CommentCard {
  display: inline-block;
  border-radius: 0.5em;
  background-color: #dcd3e84d;
  box-shadow: 2px 4px 4px 2px #d0cad9c4;
  width: 90%;
  margin: 0.5em 0 1.5em 0;
  font-family: "Dongle", sans-serif;
}

.CommentCard_header {
  font-size: 1.7em;
  text-align: left;
  padding: 0;
  margin: 0.17em 0 0 0.5em;
}

.CommentCard_date {
  text-align: left;
  justify-content: left;
  font-size: 1.5em;
  margin: 0;
  padding: 0 0 0 0.51em;
  font-style: italic;
}

.CommentCard_text {
  font-size: 1.5em;
  text-align: left;
  margin: 0;
  padding: 0 0 0 0.51em;
}

.RecipeNotFound {
  color: #2a1d28;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  font-family: "Dongle", sans-serif;
  font-size: 2.5em;
  width: 100%;
}

.RecipeNotFound_face {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 7em;
  padding: 0;
  width: 100%;
  margin: 0;
}

.RecipeNotFound_message {
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.RecipeForm {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0.5em;
  background-color: #dcd3e84d;
  font-family: "Dongle", sans-serif;
  box-shadow: 2px 8px 8px 4px #d0cad9c4;
  border-radius: 0.5em;
  color: #2a1d28;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 1.3em;
  justify-content: center;
  text-align: center;
}

.RecipeForm::-webkit-scrollbar {
  display: none;
}

.RecipeForm_header {
  font-size: 2.5em;
  width: 100%;
  height: min-content;
  margin: 0;
  padding: 0;
}

.RecipeForm_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.RecipeForm_label {
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  margin: 0;
}

.RecipeForm_input {
  height: 1.7em;
  width: 100%;
  font-size: 1.1em;
}

.RecipeForm_textarea {
  width: 100%;
  height: 7em;
  resize: none;
  overflow-y: scroll;
  font-size: 1.3em;
  overflow: auto;
}

.RecipeForm_submit_button {
  font-size: 1.1em;
  border-radius: 0.25em;
  background-color: transparent;
  margin: 1em 0 1em 0;
  border: none;
  color: #2a1d28;
}

.RecipeForm_submit_button:hover {
  cursor: pointer;
  font-style: italic;
  transform: scale(1.05);
}

@media screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }

  .RecipeGrid {
    margin: 0 -1em -1em -1em;
  }

  .Header {
    min-height: 13em;
    padding: 0.2em;
    justify-content: space-evenly;
  }

  .Header_title {
    font-size: 2.5em;
    margin-block-end: 0.2em;
    margin-block-start: 0.2em;
  }

  .Header_link {
    font-size: 1.9em;
    height: fit-content;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0.5em;
  }

  .Authentication_buttons {
    font-size: 1.9em;
  }

  .SearchBar {
    padding: 0.3em;
  }

  .AboutMe_image {
    height: 13em;
    width: 17em;
  }

  .RecipeGrid_item {
    display: flex;
    justify-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0.7em;
  }

  .RecipeCard {
    display: flex;
    max-height: 230px;
    min-width: 375px;
  }

  .RecipeCard_image {
    height: 115px;
    width: 225px;
    margin: 0.25em;
  }

  .RecipeDetails_header_title {
    font-size: 3.6em;
  }

  .RecipeDetails_header_description {
    font-size: 1.3em;
  }

  .RecipeDetails_header_image {
    height: 13em;
    margin: 0.6em;
    min-width: 70%;
    border-radius: 0.5em;
    object-fit: cover;
  }

  .RecipeDetails_body_header {
    font-size: 1.9em;
    padding: 0.15em;
  }

  .RecipeDetails_body_time {
    font-size: 1.5em;
  }

  .RecipeDetails_body_ingredients {
    font-size: 0.9em;
  }

  .RecipeDetails_body_method {
    width: 90%;
    padding: 2.5em 0;
  }
}

@media screen and (min-width: 600px) {
  .App {
    flex-direction: column;
  }

  .RecipeGrid {
    justify-content: center;
    align-content: center;
  }

  .RecipeGrid_item {
    width: fit-content;
    justify-content: center;
    justify-self: center;
    align-self: center;
  }

  .Header {
    display: flex;
    min-height: 15em;
    justify-content: space-evenly;
  }

  .Header_title {
    font-size: 3.3em;
    margin-block-end: 0;
    margin-block-start: 0;
  }

  .Header_link {
    margin-block-end: 0;
    margin-block-start: 0;
  }

  .Header_links {
    display: flex;
    align-self: space-evenly;
    height: min-content;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .SearchBar {
    padding: 0.3em;
  }

  .RecipeCard {
    max-height: 250px;
    min-width: 250px;
    display: flex;
    justify-self: center;
    margin: 0.25em;
  }

  .RecipeCard_image {
    height: 115px;
    width: 225px;
    margin: 0.25em;
  }

  .RecipeDetails_body_method {
    width: 83%;
    padding: 2.5em 0;
  }
}

@media screen and (min-width: 900px) {
  .App {
    flex-direction: row;
  }

  .Header {
    max-width: 25em;
    margin: 0.5em 0 0.5em 0.5em;
    justify-content: flex-start;
  }

  .Header_title {
    padding: 0.3em 0.3em 0.5em 0.3em;
    font-size: 3.6em;
  }

  .SearchBar {
    padding: 1em;
  }

  .Header_links {
    align-items: center;
    height: fit-content;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row wrap;
    padding: 1em;
  }

  .Header_link {
    color: #473144;
    font-size: 1.9rem;
    font-weight: 400;
    width: fit-content;
    font-family: "Covered By Your Grace", sans-serif;
  }
}
